import React, {useRef, useEffect, useState} from 'react';
import Animate from './components/Animate';
import Map from './components/Map';
import "flatpickr/dist/themes/light.css";
import Flatpickr from 'react-flatpickr';
import { Reservr } from './components/Reservr';

const Contact = () => {

  const setupCalendar = async (e, env) => {
    try {
      const response = await fetch(process.env.REACT_APP_RESERVR_API_HOST + 'api/calendar/' + process.env.REACT_APP_RESERVR_API_ID, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_RESERVR_API_KEY,
        },
      });

      const data = await response.json();

      if (response.ok) {
        fp.current.flatpickr.set('disable', data.closed_dates);
      } else {
        console.error('error', data.error);
      }
    } catch (err) {
      console.error('Network error', err);
    }

  }

  const { innerWidth: width } = window;
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  const fp = useRef(null);
  var flatpickrOptions = {
    mode: "range",
    inline: true,
    showMonths: width < 686 ? 1 : 2,
    minDate: "today",
    dateFormat: "Y-m-d",
    locale: {
      firstDayOfWeek: 1,
      rangeSeparator: " - ",
    },
    onChange: (selectedDates) => {
      if (selectedDates.length === 2) {
        var checkin = new Date(selectedDates[0]);
        checkin.setHours(15);
        var checkout = new Date(selectedDates[1]);
        checkout.setHours(11);

        setDateRange({
          start: checkin,
          end: checkout
        });
      }
    }
  }

  useEffect(() => {
    setupCalendar();
  }, [fp]);

  return (
    <>
    <div id="fh5co-contact">
      <div className="container">
        <div className="row">
          <Animate className="col-md-8 col-md-offset-2 text-center heading-section">
            <h3>Contact Information</h3>
            <p>Please find the contact details below, you can also use the form and we will get back to you as soon as possible.</p>
          </Animate>
        </div>

          <Animate className="row">
            <div className="col-md-6">
              <h3 className="section-title">One Jubilee Cottages</h3>
              <p>The cottage is easy to find in Great Haywood behind a conifer hedge with a large gravel drive next door to the dentist in Great Haywood.</p>
              <ul className="contact-info">
                <li><i className="icon-location-pin"></i>1 Brewery Lane, Great Haywood, Staffordshire, ST18 0SN</li>
                <li><i className="icon-phone2"></i><a href="tel:07929733141">07929 733 141</a></li>
                <li><i className="icon-mail"></i><a href="mailto:stay@onejubileecottages.co.uk">stay@onejubileecottages.co.uk</a></li>
                <li><i className="icon-globe2"></i><a href="https://onejubileecottages.co.uk">onejubileecottages.co.uk</a></li>
              </ul>
            </div>
            <div className="col-md-6">

            </div>
          </Animate>
      </div>
    </div>
    <div id="fh5co-blog" className="fh5co-blog fh5co-content-section">
      <div className="container">
        <div className="row">
          <Animate className="col-md-8 col-md-offset-2 text-center heading-section">
            <h3>Check Availablility</h3>
            <p>The calendar below shows live availablility.</p>
          </Animate>
        </div>
        <div className="row">
          <Animate className="col-md-10 col-md-offset-1 text-center heading-section">
            <div className="blog-text">
              <div className="prod-title"><h4>Request Booking</h4></div>
              <Flatpickr
                ref={fp}
                options={flatpickrOptions}
              />
              <br/>
              <p>At this stage your dates are not secured, once we receive your request we will get back to you with a confirmation and payment details.</p>

              <Reservr title="Reserve" dates={dateRange} />

            </div>
          </Animate>
        </div>
      </div>
    </div>
    <Map />
    </>
  );
}

export default Contact;
