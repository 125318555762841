import React, { useState } from 'react';
import { createPortal } from 'react-dom';


const ReservrButton = ({ title, onClick }) => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={onClick}
    >
      {title}
    </button>
  );
};

const ReservrModal = ({ show, dates, onClose }) => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'email':
        if (value.trim() === '') return 'Email is required';
        if (!/\S+@\S+\.\S+/.test(value)) return 'Please provide a valid email';
        return '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };

  const handleSubmit = async () => {
    setTouched({
      name: true,
      email: true
    });

    const nameError = validateField('name', formData.name);
    const emailError = validateField('email', formData.email);

    if (nameError || emailError || !dates?.start || !dates?.end) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('');
    setSubmitMessage('');

    try {
      const response = await fetch(process.env.REACT_APP_RESERVR_API_HOST + 'api/reserve', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + process.env.REACT_APP_RESERVR_API_KEY,
        },
        body: JSON.stringify({
          rental: process.env.REACT_APP_RESERVR_API_ID,
          name: formData.name,
          email: formData.email,
          message: formData.message,
          check_in: dates.start,
          check_out: dates.end
        })
      });

      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          setSubmitStatus('warning');
        } else {
          setSubmitStatus('danger');
        }
        throw new Error(data.message || data.error || 'An error occurred');
      }
      setSubmitStatus('success');
      setSubmitMessage(data.message);

    } catch (error) {
      setSubmitMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const nameError = touched.name ? validateField('name', formData.name) : '';
  const emailError = touched.email ? validateField('email', formData.email) : '';
  const dateError = !dates?.start || !dates?.end;

  const formatDate = (date) => {
    return date ? date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}) : 'Not selected';
  };

  const modalContent = (
    <>
      <div className="modal-backdrop fade show" onClick={onClose} style={{'opacity': "0.5", 'display': 'block'}}></div>
      <div className="modal reservr-modal" tabIndex="-1" style={{'display': "block"}}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              Reservation powered by <a href="https://reservr.co.uk">Reservr</a>
            </div>
            <form action="">
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <p>Check-in: {formatDate(dates?.start)}<br />
                      Check-out: {formatDate(dates?.end)}</p>
                      {dateError ? (
                        <div className="invalid-feedback">
                          {dateError}
                        </div>) : null
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                      {nameError ? (
                        <div className="invalid-feedback">
                          {nameError}
                        </div>) : null
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                       />
                       {emailError ? (
                         <div className="invalid-feedback">
                           {emailError}
                         </div>) : null
                       }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea name="message" className="form-control" placeholder="Message" rows="6"
                      onChange={handleInputChange} value={formData.message}></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">Email confirmation and payment details to follow.

                  {submitMessage && (
                    <div className={"alert alert-" + submitStatus}>{submitMessage}</div>
                  )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                <button type="button" className="btn btn-primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Reserve'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );

  if (!show) return null;

  return createPortal(modalContent, document.body);

};

const Reservr = ({ title, dates }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ReservrButton title={title} onClick={handleShow} />
      <ReservrModal show={show} dates={dates} onClose={handleClose} />
    </>
  );
};

export { ReservrModal, ReservrButton, Reservr };
export default Reservr;
